<template>
  <section class="tw-h-screen lg:tw-h-[70vh]">
    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-center">
      <NuxtImg
        :src="vars.backgroundImage"
        class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-object-center"
      />
      <div
        class="tw-absolute tw-inset-0 tw-opacity-50"
        :style="`background-color: var(--c-${vars.backgroundLayerColor}); `"
      ></div>
      <div
        class="tw-relative tw-max-w-2xl tw-px-2 tw-py-20 tw-text-center"
        :style="`color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-secondary)'}`"
      >
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="800">
          <div
            v-html="vars.titleHtml"
            class="tw-mb-5 tw-flex tw-flex-wrap tw-justify-center tw-gap-3 tw-text-center md:tw-mb-8 lg:tw-gap-4"
          ></div>
          <p v-for="(description, index) in descriptions" :key="`take-action-description-${index}`" class="tw-mb-8">
            {{ description.description }}
          </p>
          <div
            class="tw-flex tw-items-center tw-justify-around tw-gap-6 tw-whitespace-nowrap md:tw-flex-row md:tw-px-14"
          >
            <h4 v-for="(item, index) in items" :key="index">
              {{ item.title }}
            </h4>
          </div>
        </MotionGroup>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContentBanner',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },

    items() {
      return this.groupedVariables.items;
    },
  },
});
</script>
