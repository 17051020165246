<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-container tw-mx-auto tw-px-4">
      <div class="tw-flex tw-flex-col tw-items-stretch tw-gap-6 lg:tw-flex-row lg:tw-gap-12">
        <div class="tw-w-full lg:tw-w-7/12">
          <div
            class="tw-h-full tw-border tw-bg-white tw-px-6 tw-py-10 lg:tw-px-14 lg:tw-py-16"
            style="border-radius: var(--rounded); border-color: var(--c-border)"
          >
            <library-form v-if="form" :form="form" version="v2" :component="component"></library-form>
          </div>
        </div>
        <div class="tw-w-full lg:tw-w-5/12">
          <div class="tw-h-full">
            <NuxtImg
              :src="vars.photoImage"
              class="tw-h-full tw-w-full tw-object-cover"
              style="border-radius: var(--rounded)"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContactFormAndImage',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.contactForm);
  },
});
</script>

<style scoped>
.n-section-primary {
  padding: 4rem 0;
}

@media (min-width: 1024px) {
  .n-section-primary {
    padding: 6rem 0;
  }
}
</style>
